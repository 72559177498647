import React from "react";
import LearnImg from "../../Assets/Images/what_you_will_learn_icon.png";

function CourseModules({ course }) {
  return (
    <div className="course-module">
      <div className="course-module__container containers">
        <div className="cm__section1">
          <h3>{course.section_title}</h3>
          <p>{course.descp}</p>
        </div>
        <div className="cm__section2">
          <h3>What you’ll learn</h3>
          <div className="wywl__section">
            {course.boxes &&
              course.boxes.map((list, i) => {
                return (
                  <div className="wywl__sub" key={i}>
                    <img src={LearnImg} crossOrigin="anonymous" alt="What you will learn" />
                    <p>{list}</p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseModules;
