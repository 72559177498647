import React from "react";

function Tuition({ course }) {
  return (
    <div className="tuition">
      <div className="tuition__container containers">
        <div className="tuition__header">
          <h3>Tuition & Payment Options</h3>
          <p>Tuition payment is based on flexible monthly payment agreement during the 9 months training period</p>
        </div>
        <div className="tuition__sections">
          <div className="tuition__section">
            <div className="tuition__section-header ts__header-1">
              <h4>Full Tuition Payment</h4>
            </div>
            <div className="ts__content">
              <span>Total Payable tuition fee</span>
              <h2>₦{course.physical.total_fee}</h2>
              <p>
                You can opt to pay 100% of your total fees, without additional charges during the training period, until
                the end of the program.
              </p>
            </div>
          </div>
          {course.physical.monthly_fee !== "" ? (
            <div className="tuition__section">
              <div className="tuition__section-header">
                <h4>Installment Payments</h4>
              </div>
              <div className="ts__content">
                <span>Monthly payment plan</span>
                <h2>₦{course.physical.monthly_fee}</h2>
                <p>
                  Explore our Installment payment plan which includes payment as you learn through the {course.duration}{" "}
                  period of the entire program. There is no additional fee inclusive. Payment plan terminates at the end
                  of the {course.duration}.
                </p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Tuition;
